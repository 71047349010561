* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  margin: 0;
  background-color: #004560;
  background-image: url('/jumbotron.jpg');
  background-size: cover;
  padding-top: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fbfbfb;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
