.Home {

}

.Home h1 {
    font-weight: 400;
}

.Home p {
    font-weight: 300;
}

.Home a {
    color: #fbfbfb;
    text-decoration: none;
    font-size: 1.5em;
}

.Home a:hover {
    color: #ff8800;
}