.App {
  height: 100%;
  display: grid;
  grid-template-columns: 60vw 40vw;
  grid-template-rows: 80vh 20vh;
  grid-template-areas: 
    "Content Content"
    "Navigation LogoBar"; 
}

@media only screen and (max-width: 500px) {
  .App {
    height: 100%;
    grid-template-columns: 100vw;
    grid-template-rows: 80% 5% 15%;
    grid-template-areas: 
      "Content"
      "Navigation"
      "LogoBar"; 
  }
}