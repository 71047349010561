.Project {
  height: 50%;
  width: 25%;
  background-size: cover;
  background-position: top center;
  /* margin: 0.5em; */
}

.Project div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 27, 54, 0.7);
  color: #ff8800;
  text-decoration: none;
  padding: 0.5em;
}

.Project span {
  text-align: right;
  vertical-align: bottom;
  width: 60%;
}

.Project:hover div {
  opacity: 1;
}

@media only screen and (max-width: 500px) {
  .Project {
    height: 33.33%;
    width: 50%;
  }
}
