.Portfolio {
    display: flex;
    flex-direction: column;
}

.Portfolio h2 {
    font-weight: 400;
}

.Portfolio p {
    font-weight: 300;
}

.ProjectContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
}