.page {
    grid-area: Content;
    padding: 4em;
    font-size: 1.4em
}

@media only screen and (max-width: 500px) {
    .page {
        padding: 2em;
        font-size: 1.0em;
    }
}