.LogoBar {
    grid-area: LogoBar;
    max-width: 100%;
    max-height: 100%;
    padding: 0.5em;
}

.LogoBar svg {
    height: 100%;
    width: 100%;
}