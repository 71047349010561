.Navigation {
    grid-area: Navigation;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
}

.Navigation a {
    color: #fbfbfb;
    text-decoration: none;
    font-size: 2em;
    transition: border 0.5s ease-in-out;
    border-bottom: 2px solid rgba(0,0,0,0);
}

.Navigation a:hover {
    color: #ff8800;
}

.Navigation .chosen {
    border-bottom: 2px solid #ff8800;
}

@media only screen and (max-width:500px) {
    .Navigation a {
        font-size: 1.5em;
    }
}